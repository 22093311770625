/**
* SidebarMenu.tsx (abstractuser) *

* Copyright © 2020 InstaLOD GmbH - All Rights Reserved. *

* Unauthorized copying of this file, via any medium is strictly prohibited.
* This file and all it's contents are proprietary and confidential. *

* Maintained by Sai Charan K, 2020 
* @file SidebarMenu.tsx
* @author Sai Charan K
* @copyright 2020 InstaLOD GmbH. All rights reserved.
* @section License
*/

import React, { useEffect, useState, useRef, Dispatch, MutableRefObject } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getAuthState, IAuthState, logoutAction } from '../../../Store/AuthSlice';
import { getLogoAction, getSettingsState, ISettingsState } from '../../../Store/SettingsSlice';
import {
  menuItemClick,
  dropDownMenuClickHandler,
  removeClassNameFromDropdownMenu
} from '@abstract/abstractwebcommon-client/Sidebar/menuItemUtils';
import SidebarPage, { IMenuItem } from '@abstract/abstractwebcommon-client/Sidebar/SidebarPage';
import {
  linkedApplicationsSidebarItems,
  staticLinkSidebarItems
} from '@abstract/abstractwebcommon-client/Sidebar/sharedMenus';
import { TFunction } from 'i18next';
import { Helmet } from 'react-helmet';
import {
  tabletMediumBreakpointWidth,
  userAuthenticationVerificationUrlPath
} from '@abstract/abstractwebcommon-client/Constants';
import UserTotalCount from './MenuBadgeCount/UserTotalCount';
import { ISidebarMenu } from './types/sidebar';
import {
  getStaticLinksState,
  IStaticLinksState
} from '@abstract/abstractwebcommon-client/store/StaticLinksSlice';
import { getAllStaticLinksAction } from '../../../Store/StaticLinksSlice';
import { IApplications } from '@abstract/abstractwebcommon-shared/interfaces/user/applications';
import { LocalStorage } from '@abstract/abstractwebcommon-client/utils/sharedLocalStorage';
import { RouteName } from '../../../Utils/routesNames';
import { SharedCommomRouteName } from '@abstract/abstractwebcommon-client/utils/sharedRoutesNames';
import i18n from '../../../Services/I18n';
import {
  IUserApplicationsState,
  getUserApplicationsForSidebarAction,
  getUserApplicationsState,
  userApplicationsAction
} from '../../../Store/UserApplicationsSlice';
import {
  IUserState,
  getUserProfileInformationAction,
  getUserState
} from '../../../Store/UserSlice';
import { IUserProfile } from '@abstract/abstractwebcommon-shared/interfaces/user/user';

const SidebarMenu = ({
  isAdmin,
  themeMode,
  didChangeTheme,
  logoURL,
  languageSettingsMode,
  didChangeLanguage
}: ISidebarMenu): JSX.Element => {
  const history: any = useHistory();
  const t: TFunction = useTranslation().t;
  const [isLogout, setIsLogout] = useState<boolean>(false);
  const [isClientMenuPopulated, setClientMenuPopulated] = useState<boolean>(false);
  const authState: IAuthState = useSelector(getAuthState);
  const userState: IUserState = useSelector(getUserState);
  const staticLinksState: IStaticLinksState = useSelector(getStaticLinksState);
  const userApplicationsState: IUserApplicationsState = useSelector(getUserApplicationsState);
  const dispatch: Dispatch<any> = useDispatch();
  const templateEditorMenu: HTMLCollection =
    document.getElementsByClassName('template-editor'); /**< Template editor menu */
  const [isLogoutAll, setLogoutAll] = useState<boolean>(false); /**< isLogoutAll */

  const settingsState: ISettingsState = useSelector(getSettingsState); /**< Settings State */
  const noImageContainer: JSX.Element = (
    <h4 className="text-light">
      {settingsState.safeSettings?.applicationTitle || t('/.page_title')}
    </h4>
  ); /**< NoImageContainer */

  const menu: MutableRefObject<any> = useRef(null);
  const [menuItems, setMenuItems] = useState<IMenuItem[] | null>(null);
  const [sidebarInit, setSidebarInit] = useState<boolean>(false);
  const [extraMenuStyles, setExtraMenuStyles] = useState<string>(null);
  const [isMobile, setMobile] = useState<boolean>(false);
  const templatesPathNames: string[] = [
    RouteName.adminTemplateEditorRoute,
    RouteName.adminCSSEditorRoute
  ];

  const getScreenWidthSize = () => {
    if (window.innerWidth < tabletMediumBreakpointWidth) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  };

  useEffect(() => {
    if (LocalStorage.getXAuthToken()) {
      dispatch(getAllStaticLinksAction());
      dispatch(getUserApplicationsForSidebarAction());
    }
    getScreenWidthSize();
  }, []);

  // Get admin menu items
  const getAdminMenuItems = (): Array<IMenuItem> => {
    /// menu items for admin user
    const adminMenuItems: Array<IMenuItem> = [
      {
        label: t('/admin.sidebar.menu.dashboard'),
        icon: 'far fa-home sidebar_icon',
        url: RouteName.adminDashboardRoute,
        pathToHighlight: RouteName.adminDashboardRoute,
        command: (event: any) => {
          event.originalEvent.preventDefault();
          removeClassNameFromDropdownMenu(templateEditorMenu);
          menuItemClick(menu, setMenuItems, history, RouteName.adminDashboardRoute, '', isMobile);
        }
      },
      {
        label: (
          <div className="count-items-container">
            {t('/admin.sidebar.menu.usermanagement')}
            {isAdmin && <UserTotalCount />}
          </div>
        ),
        icon: 'far fa-users sidebar_icon',
        url: SharedCommomRouteName.adminUserManagementRoute,
        pathToHighlight: SharedCommomRouteName.adminUserManagementRoute,
        command: (event: any) => {
          event.originalEvent.preventDefault();
          removeClassNameFromDropdownMenu(templateEditorMenu);
          menuItemClick(
            menu,
            setMenuItems,
            history,
            SharedCommomRouteName.adminUserManagementRoute,
            '',
            isMobile
          );
        }
      },
      {
        label: t('/admin.sidebar.menu.rolemanagement'),
        icon: 'far fa-user-edit sidebar_icon',
        url: RouteName.adminRoleManagementRoute,
        pathToHighlight: RouteName.adminRoleManagementRoute,
        command: (event: any) => {
          event.originalEvent.preventDefault();
          removeClassNameFromDropdownMenu(templateEditorMenu);
          menuItemClick(
            menu,
            setMenuItems,
            history,
            RouteName.adminRoleManagementRoute,
            '',
            isMobile
          );
        }
      },
      {
        label: t('/admin.sidebar.menu.applicationmanagement'),
        icon: 'fas fa-th sidebar_icon',
        url: RouteName.adminApplicationManagementRoute,
        pathToHighlight: RouteName.adminApplicationManagementRoute,
        command: (event: any) => {
          event.originalEvent.preventDefault();
          removeClassNameFromDropdownMenu(templateEditorMenu);
          menuItemClick(
            menu,
            setMenuItems,
            history,
            RouteName.adminApplicationManagementRoute,
            '',
            isMobile
          );
        }
      },
      {
        label: t('/admin.sidebar.menu.templateeditor'),
        icon: 'fas fa-drafting-compass sidebar_icon',
        menuClassName:
          templatesPathNames.indexOf(window.location.pathname) > -1
            ? 'template-editor panel-menu-activated-item'
            : 'template-editor',
        command: (event) => {
          dropDownMenuClickHandler(event, templatesPathNames, templateEditorMenu);
        },
        items: [
          {
            label: t('/admin.sidebar.menu.template-editor'),
            icon: 'fas fa-drafting-compass sidebar_icon',
            url: RouteName.adminTemplateEditorRoute,
            pathToHighlight: RouteName.adminTemplateEditorRoute,
            command: (event: any) => {
              event.originalEvent.preventDefault();
              return menuItemClick(
                menu,
                setMenuItems,
                history,
                RouteName.adminTemplateEditorRoute,
                '',
                isMobile
              );
            }
          },
          {
            label: t('/admin.sidebar.menu.csseditor'),
            icon: 'fas fa-drafting-compass sidebar_icon',
            url: RouteName.adminCSSEditorRoute,
            pathToHighlight: RouteName.adminCSSEditorRoute,
            command: (event: any) => {
              event.originalEvent.preventDefault();
              menuItemClick(
                menu,
                setMenuItems,
                history,
                RouteName.adminCSSEditorRoute,
                '',
                isMobile
              );
            }
          }
        ]
      },
      {
        label: t('/admin.sidebar.menu.settings'),
        icon: 'far fa-cog sidebar_icon',
        url: RouteName.adminSettingsRoute,
        pathToHighlight: RouteName.adminSettingsRoute,
        command: (event: any) => {
          event.originalEvent.preventDefault();
          removeClassNameFromDropdownMenu(templateEditorMenu);
          menuItemClick(menu, setMenuItems, history, RouteName.adminSettingsRoute, '', isMobile);
        }
      },
      {
        label: t('/admin.sidebar.menu.logs'),
        url: RouteName.adminLogsRoute,
        pathToHighlight: RouteName.adminLogsRoute,
        icon: 'far fa-calendar sidebar_icon',
        command: (event: any) => {
          event.originalEvent.preventDefault();
          removeClassNameFromDropdownMenu(templateEditorMenu);
          menuItemClick(menu, setMenuItems, history, RouteName.adminLogsRoute, '', isMobile);
        }
      }
    ];

    return adminMenuItems;
  };

  /// static link and linked applications as menu item - shared across admin and non-admin users
  const sharedMenuItems = [
    ...linkedApplicationsSidebarItems(
      userApplicationsState.userApplications,
      `${window.location.origin}${userAuthenticationVerificationUrlPath}`
    ),
    ...staticLinkSidebarItems([...staticLinksState.allStaticLinks])
  ];

  /// Initialize active menu on page reload
  useEffect(() => {
    if (sidebarInit) {
      setSidebarInit(false);
      const path = window.location.pathname;

      menuItemClick(menu, setMenuItems, history, path, '', false);
      dispatch(getLogoAction({}));
    }
  }, [sidebarInit, dispatch, setSidebarInit, menuItemClick]);

  /// populate menuItems based on role
  useEffect(() => {
    if (isAdmin) {
      const updatedMenuItems: IMenuItem[] = getAdminMenuItems();
      setMenuItems([...updatedMenuItems, ...sharedMenuItems]);
    } else {
      setMenuItems([...sharedMenuItems]);
    }
    /// Initialization after refresh
    setSidebarInit(true);
  }, [
    isAdmin,
    isClientMenuPopulated,
    staticLinksState.allStaticLinks,
    userApplicationsState.userApplications,
    languageSettingsMode
  ]);

  /// Checks if the user is authenticated, else performs logout
  useEffect(() => {
    if (!authState.isAuthenticated) {
      history.push({ pathname: SharedCommomRouteName.loginRoute });
    }
    if (isLogout) {
      dispatch(logoutAction());
      dispatch(userApplicationsAction.reset());
      setIsLogout(false);
    }
    if (isLogoutAll) {
      // Logout all applications
      setLogoutAll(false);
      const userApplications: IApplications[] = userApplicationsState.userApplications
        .filter(
          (eachApplication: IApplications) => eachApplication.isUserPermissionGranted === true
        )
        .map((eachApplication) => {
          return {
            applicationUUID: eachApplication.applicationUUID,
            verificationURL: eachApplication.verificationURL
          };
        });

      const parameters: URLSearchParams = new URLSearchParams({
        logoutAll: 'true',
        userApplications: JSON.stringify(userApplications)
      }); /**< Query parameters */
      window.location.href = `${RouteName.authVerifyRoute}?${parameters.toString()}`;
    }
  }, [isLogout, setIsLogout, authState, dispatch, history, isLogoutAll, setLogoutAll]);

  // Get user profile card information
  useEffect(() => {
    if (LocalStorage.getXUserUUID()) {
      dispatch(getUserProfileInformationAction(LocalStorage.getXUserUUID()));
    }
  }, []);

  const handleClientMenuItemsPopulated = (styles: string) => {
    setClientMenuPopulated(true);
    setExtraMenuStyles(styles);
  };

  //Note: Get admin menu items when language changes
  window.addEventListener('storage', (event: StorageEvent) => {
    if (event.key === LocalStorage.languageSettingsModeKey) {
      if (isAdmin) {
        const updatedMenuItems: IMenuItem[] = getAdminMenuItems();
        setMenuItems([...updatedMenuItems, ...sharedMenuItems]);
      } else {
        setMenuItems([...sharedMenuItems]);
      }
      /// Initialization after refresh
      setSidebarInit(true);
    }
  });

  return (
    <>
      <Helmet>
        <style type="text/css">{`
          ${extraMenuStyles}
        `}</style>
      </Helmet>

      <SidebarPage
        menu={menu}
        menuItems={menuItems}
        logoAlt={t('I18N.sidebar.logo_alt')}
        logoNoImageContainer={noImageContainer}
        logoUrl={logoURL}
        onAccount={() =>
          menuItemClick(
            menu,
            setMenuItems,
            history,
            isAdmin ? RouteName.adminProfileRoute : RouteName.userProfileRoute
          )
        }
        onLogout={() => setIsLogout(true)}
        userInformation={userState?.userProfileCardInformation ?? ({} as IUserProfile)}
        handleUserMenuItemsPopulated={handleClientMenuItemsPopulated}
        themeMode={themeMode}
        didChangeTheme={didChangeTheme}
        logoutAllText={t('/admin.sidebar.menu.sign_out_all')}
        onLogoutAll={() => setLogoutAll(true)}
        languageSettingsMode={languageSettingsMode}
        didChangeLanguage={didChangeLanguage}
        i18nService={i18n}
      />
    </>
  );
};

export default SidebarMenu;
