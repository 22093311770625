/**
* Logs.tsx (abstractuser) *

* Copyright © 2020 InstaMaterial GmbH - All Rights Reserved. *

* Unauthorized copying of this file, via any medium is strictly prohibited.
* This file and all it's contents are proprietary and confidential. *

* Maintained by Pascal Mayr, 2020 
* @file Logs.tsx
* @author Pascal Mayr
* @copyright 2020 InstaMaterial GmbH. All rights reserved.
* @section License
* @modified by Sai Charan K
*/

import React, { Dispatch, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  logActions,
  exportLogsAction,
  getLogsListAction,
  getLogState,
  ILogState,
  updateLogErrorAction,
  deleteLogsAction
} from '../../../Store/LogSlice';
import { prepareFilter } from '../../../Services/PrepareFilter';
import LogTable from '@abstract/abstractwebcommon-client/Logs/LogTable';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import { MultiSelect } from 'primereact/multiselect';
import { omit } from 'lodash';
import LogDetails from '@abstract/abstractwebcommon-client/Logs/LogDetails';
import { endOfDay, startOfDay } from 'date-fns';
import { TFunction } from 'i18next';
import {
  defaultTableLimit,
  defaultTableOptions
} from '@abstract/abstractwebcommon-client/Constants';
import { Button } from 'react-bootstrap';
import { Range as IDateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { ManualDateRangePicker } from '@abstract/abstractwebcommon-client/DateRangePicker/dateRangePicker';
import SearchBar from '@abstract/abstractwebcommon-client/SearchBar/SearchBar';
import { ITablePayload } from '@abstract/abstractwebcommon-shared/interfaces/pagination';
import ExpansionRow from '@abstract/abstractwebcommon-client/Table/ExpansionRow/ExpansionRow';
import { showToast } from '@abstract/abstractwebcommon-client/AlertToast/AlertToast';
import {
  capitalizeFirstCharacterOnString,
  formatDate
} from '@abstract/abstractwebcommon-shared/utils/sharedFunctions';
import { handleCheckboxIconClick, handleHeaderCheckboxClick } from '../../../Utils/helpers';
import ConfirmationDialog, {
  ConfirmationDialogMode
} from '@abstract/abstractwebcommon-client/ConfirmationDialog/ConfirmationDialog';
import { PruneLogsTimeFrameEnum } from '@abstract/abstractwebcommon-shared/enum/log';
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown';

/**
 * Defines the interface for the prune logs dropdown component.
 * @interface IPruneLogsDropdownOption
 */
interface IPruneLogsDropdownOption {
  name: string /**< Defines the label option to display in the dropdown component. */;
  code: string /**< Defines the selected value option of the dropdown component. */;
}
/**
 * @interface IMultiSortMeta
 */
interface IMultiSortMeta {
  field: string /**< field to apply to the order on search */;
  order: number /**< order to apply to the order on search (DESC or ASC) */;
}

const Logs = (): JSX.Element => {
  const logState: ILogState = useSelector(getLogState);
  const dispatch: Dispatch<any> = useDispatch();
  const t: TFunction = useTranslation().t;

  const [isLogsLoaded, setIsLogsLoaded] = useState<boolean>(false);
  const [payload, setPayload] = useState<ITablePayload>({
    limit: defaultTableLimit,
    skip: 0,
    sort: {
      createdAt: 'DESC'
    },
    searchTerm: ''
  });
  const [expandedRows, setExpandedRows] = useState<any>({});
  const node: any = useRef();

  let defaultSortField, defaultSortOrder: string; // Default sort criteria from tableData.
  Object.keys(payload.sort).forEach((key) => {
    defaultSortField = key; // Sortfield
    defaultSortOrder = payload.sort[key]; //Sortorder
  });
  const [multiSortMeta, setMultiSortMeta] = useState<IMultiSortMeta[]>([
    { field: defaultSortField, order: defaultSortOrder === 'ASC' ? 1 : -1 }
  ]);

  const [tableFilters, setTableFilters] = useState<any>({});
  const [searchTerm, setSearchTerm] = useState<string>();

  const [selectedLogs, setSelectedLogs] = useState<any[]>([]);
  const defaultDate: IDateRange[] = [
    {
      startDate: startOfDay(new Date(2020, 1, 1)),
      endDate: endOfDay(new Date()),
      key: 'selection'
    }
  ];
  const [selectedDateRange, setSelectedDateRange] = useState<any>(defaultDate);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState<boolean>(false);
  const [durationOptions, setDurationOptions] = useState<any[]>([]);

  const defaultDeleteLogsOptions: IPruneLogsDropdownOption = {
    name: PruneLogsTimeFrameEnum.OneYear,
    code: Object.entries(PruneLogsTimeFrameEnum).find(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      ([_, enumValue]) => enumValue === PruneLogsTimeFrameEnum.OneYear
    )?.[0]
  };

  // State to manage the selected option for the prune logs functionality.
  const [selectedDeleteLogsOption, setSelectedDeleteLogsOption] =
    useState<IPruneLogsDropdownOption>(defaultDeleteLogsOptions);

  const categories = [
    t('I18N.logs.categories.user'),
    t('I18N.logs.categories.auth'),
    t('I18N.logs.categories.application'),
    t('I18N.logs.categories.role'),
    t('I18N.logs.categories.settings'),
    t('I18N.logs.categories.templates'),
    t('I18N.logs.categories.address'),
    t('I18N.logs.categories.userApplications'),
    t('I18N.logs.categories.securityViolation'),
    t('I18N.logs.categories.migration'),
    t('I18N.logs.categories.info'),
    t('I18N.logs.categories.error')
  ];

  const securityLevels: string[] = [
    t('I18N.logs.securityLevels.info'),
    t('I18N.logs.securityLevels.danger')
  ];

  const [selectedCategories, setSelectedCategories] = useState<any[]>([]);
  const [selectedSecurityLevels, setSelectedSecurityLevels] = useState<any[]>([]);

  const handleDateChangeFilter = (dateRange: IDateRange[]): void => {
    let updatedFilter = {};
    if (dateRange !== undefined && dateRange !== null && dateRange.length > 0) {
      /// If values exist then update the filter object with category key
      updatedFilter = {
        ...tableFilters,
        dateRange: {
          value: {
            startDate: startOfDay(new Date(dateRange[0].startDate)),
            endDate: endOfDay(new Date(dateRange[0].endDate))
          }
        }
      };
    } else {
      /// If not omit category key from object
      updatedFilter = omit(tableFilters, 'dateRange');
    }
    setTableFilters(updatedFilter);
    onFilter({ filters: updatedFilter });
  };

  useEffect(() => {
    const durationOptionsValues: string[] = Object.keys(PruneLogsTimeFrameEnum);
    const durationOptionsLabels: string[] = Object.values(PruneLogsTimeFrameEnum);
    const mappedDurationOptions: any = durationOptionsLabels.map(
      (durationLabel: string, index: number) => {
        return { name: durationLabel, code: durationOptionsValues[index] };
      }
    );
    setDurationOptions(mappedDurationOptions);
  }, []);

  /// Listens for state changes of isLogsLoaded and logError and displays toast message accordingly.
  useEffect(() => {
    if (!isLogsLoaded) {
      dispatch(getLogsListAction(payload));
      setIsLogsLoaded(true);
    }
    if (logState.logError !== null) {
      showToast({
        severity: 'error',
        summary: t('I18N.error_messages.failed'),
        detail: logState.logError?.message || logState.logError
      });
      dispatch(updateLogErrorAction(null));
    }
  }, [dispatch, isLogsLoaded, setIsLogsLoaded, payload, logState, t]);

  // Listens for export success messages
  useEffect(() => {
    if (logState.isLogExported) {
      showToast({
        severity: 'success',
        summary: t('I18N.success_messages.export_logs_success')
      });
    }
    dispatch(logActions.reset());
  }, [logState.isLogExported]);

  /// Triggers on every page change to load new data
  const onPage = (event: any): void => {
    const updatedPayload = payload;
    updatedPayload.limit = event.rows || defaultTableLimit;
    updatedPayload.skip = event.first || 0;
    setPayload(updatedPayload);
    setIsLogsLoaded(false);
  };

  /// Triggers on dateRange selection change - Sets the value to the filter
  const onDateRangeChange = (event: any): void => {
    setSelectedDateRange([event.selection]);
    handleDateChangeFilter([event.selection]);
  };

  const onExportCsv = () => {
    if (searchTerm) {
      Object.assign(payload, { searchTerm: searchTerm });
    }
    dispatch(exportLogsAction(payload));
  };

  /// This specifies the template for row expansion.
  const GetRowExpansionTemplate = ({ rowData }: any) => {
    // Capitalize the first letter of Security level
    let securityLevel: string = rowData['level'];
    securityLevel = securityLevel && capitalizeFirstCharacterOnString(securityLevel);

    return (
      <>
        <tr>
          <th>{t('/admin/logs.logs_fieldset.datatable.columns.created')}</th>
          <td>{rowData.createdAt ? formatDate(rowData.createdAt, { isTime: true }) : ''}</td>
        </tr>
        <tr>
          <th>{t('/admin/logs.logs_fieldset.datatable.columns.author')}</th>
          <td>{rowData['author']}</td>
        </tr>
        <tr>
          <th>{t('/admin/logs.logs_fieldset.datatable.columns.category')}</th>
          <td>{rowData['category']}</td>
        </tr>
        <tr>
          <th>{t('/admin/logs.logs_fieldset.datatable.columns.securityLevel')}</th>
          <td>{securityLevel}</td>
        </tr>
        <tr>
          <th>{t('/admin/logs.logs_fieldset.datatable.columns.remote')}</th>
          <td>{rowData['ip']}</td>
        </tr>
        <tr>
          <th>{t('/admin/logs.logs_fieldset.datatable.columns.activity')}</th>
          <td>{rowData['activity']}</td>
        </tr>
        {rowData.details && (
          <>
            <tr>
              <th colSpan={100}>{t('/admin/logs.logs_fieldset.datatable.columns.details')}</th>
            </tr>
            <div className="px-3">
              <Row>
                <Col sm="12">
                  <p className="mb-0">{getLogDetails(rowData)}</p>
                </Col>
              </Row>
            </div>
          </>
        )}
      </>
    );
  };

  /// This will render the expansion row template.
  const renderExpansionRows = (rowData: any) => (
    <>
      <ExpansionRow>
        <GetRowExpansionTemplate rowData={rowData} />
      </ExpansionRow>

      <ExpansionRow isSmallBreakpoint={true}>
        <GetRowExpansionTemplate rowData={rowData} />
      </ExpansionRow>
    </>
  );

  /// Triggers on sort btn click. This sends a reques to the backend with the specific column information along with the ASC|DESC indicator
  const onSort = (event: any): void => {
    const updatedPayload = payload;
    updatedPayload.sort = {};
    for (let i = 0; i < event.multiSortMeta.length; i++) {
      updatedPayload.sort[event.multiSortMeta[i].field] =
        event.multiSortMeta[i].order === 1 ? 'ASC' : 'DESC';
    }
    setPayload(updatedPayload);
    setIsLogsLoaded(false);
    setMultiSortMeta(event.multiSortMeta);
  };

  /// Triggers on input data change on the column heading. This updates the payload to send a request to the backend with the specific column data.
  const onFilter = (event: any): void => {
    const updatedPayload = payload;
    if (typeof event !== 'string') {
      const keys = Object.keys(event.filters);
      updatedPayload.filter = prepareFilter(event, keys);
    }

    if (typeof event === 'string') {
      Object.assign(updatedPayload, { searchTerm: event });
    }

    setPayload(updatedPayload);
    setTableFilters(event.filters);
    setIsLogsLoaded(false);

    if (event.clear) {
      setSelectedCategories([]);
      setSelectedSecurityLevels([]);
      setSelectedDateRange([
        {
          startDate: startOfDay(new Date(2020, 1, 1)),
          endDate: endOfDay(new Date()),
          key: 'selection'
        }
      ]);
    }
  };

  /// Triggerd on rowExpand
  const expandRow = (event: any): void => {
    setExpandedRows({ [event.data.id]: true });
  };

  /// Triggers on every checkbox selection change in the UI.
  const onSelectionChange = (event: any): void => {
    const selectedIds: any = event.value;
    setSelectedLogs(selectedIds);
  };

  /// Triggers on category multiselect change - Sets the value to the filter
  const onCategoryChange = (event: any): void => {
    setSelectedCategories(event.value);
    const selectedValue: string[] = [...event.value]; /**< Selected categories */
    let updatedFilter = {};
    if (selectedValue && selectedValue.length) {
      // To add [Settings, Setting] to category filter
      if (selectedValue.includes(t('I18N.logs.categories.settings'))) {
        selectedValue.push(t('I18N.logs.categories.setting'));
      }
      /// If values exist then update the filter object with category key
      updatedFilter = { ...tableFilters, category: { value: selectedValue, matchMode: 'IN' } };
    } else {
      /// If not omit category key from object
      updatedFilter = omit(tableFilters, 'category');
    }
    setTableFilters(updatedFilter);
    onFilter({ filters: updatedFilter });
  };

  /// Triggers on security level multiselect change - Sets the value to the filter
  const onSecurityLevelChange = (event: any): void => {
    setSelectedSecurityLevels(event.value);
    let updatedFilter: any = {};
    if (event.value && event.value.length) {
      /// If values exist then update the filter object with category key
      updatedFilter = { ...tableFilters, level: { value: event.value, matchMode: 'IN' } };
    } else {
      /// If not omit category key from object
      updatedFilter = omit(tableFilters, 'level');
    }
    setTableFilters(updatedFilter);
    onFilter({ filters: updatedFilter });
  };

  /// Initialze multiselect for log category
  const categoryFilter: JSX.Element = (
    <MultiSelect
      value={selectedCategories}
      options={categories}
      onChange={onCategoryChange}
      placeholder={t('I18N.logs.category_filter_placeholder')}
      appendTo={document.body}
      className="p-column-filter"
      filter
      panelClassName="logCategory" /**< Style applied to multiselect-items-wrapper. */
      pt={{
        checkboxIcon: {
          onClick: (event: any) => handleCheckboxIconClick(event)
        },
        headerCheckbox: {
          onClick: (event: any) => handleHeaderCheckboxClick(event)
        }
      }}
    />
  );

  /// Initialze multiselect for log security level
  const securityLevelFilter: JSX.Element = (
    <MultiSelect
      value={selectedSecurityLevels}
      options={securityLevels}
      onChange={onSecurityLevelChange}
      placeholder={t('I18N.logs.security_filter_placeholder')}
      appendTo={document.body}
      className="p-column-filter"
      filter
      pt={{
        checkboxIcon: {
          onClick: (event: any) => handleCheckboxIconClick(event)
        },
        headerCheckbox: {
          onClick: (event: any) => handleHeaderCheckboxClick(event)
        }
      }}
    />
  );

  /// Header
  const header: JSX.Element = (
    <>
      <div className="d-flex justify-content-between align-items-center mobile-screen-width-search-bar">
        <div className="headerTableContainer">
          <ManualDateRangePicker
            node={node}
            date={selectedDateRange}
            defaultDate={defaultDate}
            handleDateChange={onDateRangeChange}
          />

          <Button
            className="ml-1 p-button-icon-only secondary-border-radius"
            onClick={() => onExportCsv()}
            disabled={logState.isExportingLog}
            variant="primary">
            <i className="bi bi-download bi bi-align-center"></i>
          </Button>

          <Button
            className="ml-1 p-button-icon-only secondary-border-radius d-none d-xxl-block"
            onClick={() => setShowDeleteConfirmationModal(true)}
            variant="danger">
            <i className="bi bi-trash bi bi-align-center"></i>
          </Button>
        </div>

        <div className="ml-1 headerTableContainer header-search-filter">
          <SearchBar
            setSearchTerm={setSearchTerm}
            onSearchTermChanged={(data: string) => onFilter(data)}
          />
          {categoryFilter}
          {securityLevelFilter}
        </div>
      </div>
    </>
  );

  const getLogDetails = (rowData: any): JSX.Element => {
    return <LogDetails details={rowData.details} />;
  };

  /**
   * Defines the method to execute when the prune logs confirmation modal is closed.
   * @returns void
   */
  const onRejectDeleteLogsConfirmation = (): void => {
    setShowDeleteConfirmationModal(false);
    setSelectedDeleteLogsOption(defaultDeleteLogsOptions);
  };

  /**
   * Defines the method to execute when the Delete button of the confirmation modal is clicked.
   * @returns void
   */
  const onAcceptDeleteLogsConfirmation = (): void => {
    dispatch(deleteLogsAction(selectedDeleteLogsOption.code));
  };

  // Listens for changes in the log 'isRequestingDeleteLogsEndpoint' and 'isDeleteLogsEndpointSuccess' Redux states.
  // We should close the delete confirmation modal in case the endpoint returns a success status code.
  useEffect(() => {
    if (!logState.isRequestingDeleteLogsEndpoint && logState.isDeleteLogsEndpointSuccess) {
      onRejectDeleteLogsConfirmation();
      dispatch(getLogsListAction(payload));
    }
  }, [logState.isRequestingDeleteLogsEndpoint, logState.isDeleteLogsEndpointSuccess]);

  return (
    <>
      <LogTable
        data={logState.logs}
        header={header}
        rowsPerPage={defaultTableOptions}
        payload={payload}
        totalRecords={logState.totalRecords}
        onPage={onPage}
        expandedRows={expandedRows}
        getRowExpansionTemplate={renderExpansionRows}
        setExpandedRows={setExpandedRows}
        expandRow={expandRow}
        multiSortMeta={multiSortMeta}
        onSort={onSort}
        onFilter={onFilter}
        selectedList={selectedLogs}
        onSelectionChange={onSelectionChange}
        sortMode="multiple"
        dataKey={'id'}
        isLoading={!logState.logs}
        parentClass={'responsiveBaseDataTable logDataTable'}
        className={'logDataTable'}
        categoryFilterElement={categoryFilter}
        securityLevelFilterElement={securityLevelFilter}
        tableFilters={tableFilters}
        logDetails={getLogDetails}
        refreshList={() => onFilter({ filters: [], clear: true })}
        showExpander={true}
      />

      <ConfirmationDialog
        dialogTitle={t('I18N.logs.deleteConfirmationModal.deleteTitle')}
        isShowConfirmationModal={showDeleteConfirmationModal}
        onReject={() => onRejectDeleteLogsConfirmation()}
        onAccept={() => onAcceptDeleteLogsConfirmation()}
        cancelButtonText={t('I18N.logs.deleteConfirmationModal.rejectButtonLabel')}
        confirmButtonText={t('I18N.logs.deleteConfirmationModal.acceptButtonLabel')}
        modalMode={ConfirmationDialogMode.Delete}>
        <>
          <Row as={Col} xs="2" sm="2" md="2" lg="2" xl="4">
            {t('I18N.logs.deleteConfirmationModal.firstConfirmationMessage')}
            <br />
            {t('I18N.logs.deleteConfirmationModal.secondConfirmationMessage')}
            <br />
            {t('I18N.logs.deleteConfirmationModal.thirdConfirmationMessage')}
          </Row>
          <Row as={Col} xs="2" sm="2" md="2" lg="2" xl="4" className="mt-3">
            <Dropdown
              name="deleteLogsDropdown"
              optionLabel="name"
              value={selectedDeleteLogsOption}
              options={durationOptions}
              onChange={(event: DropdownChangeEvent) =>
                setSelectedDeleteLogsOption({ name: event.value.name, code: event.value.code })
              }
              disabled={logState.isRequestingDeleteLogsEndpoint}
            />
          </Row>
        </>
      </ConfirmationDialog>
    </>
  );
};

export default Logs;
